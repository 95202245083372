// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("./../../../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-appmodern-ar-js": () => import("./../../../src/pages/appmodernAr.js" /* webpackChunkName: "component---src-pages-appmodern-ar-js" */),
  "component---src-pages-appmodern-js": () => import("./../../../src/pages/appmodern.js" /* webpackChunkName: "component---src-pages-appmodern-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-return-policy-ar-js": () => import("./../../../src/pages/returnPolicyAr.js" /* webpackChunkName: "component---src-pages-return-policy-ar-js" */),
  "component---src-pages-return-policy-js": () => import("./../../../src/pages/returnPolicy.js" /* webpackChunkName: "component---src-pages-return-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/termsAndConditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-and-policies-js": () => import("./../../../src/pages/termsAndPolicies.js" /* webpackChunkName: "component---src-pages-terms-and-policies-js" */),
  "component---src-pages-termsandcond-ar-js": () => import("./../../../src/pages/termsandcondAr.js" /* webpackChunkName: "component---src-pages-termsandcond-ar-js" */)
}

